import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

const COMPONENT_HEIGHTS = {
  MOBILE: {
    HOW_WE_RANK: '51px',
    SUGGESTED_PRICING_CAROUSEL: '527px',
    SUGGESTED_VEHICLE_PRICING_CAROUSEL: '692px',
    CORE_FOOTER_PRICING_CAROUSEL: '523px',
    APPRAISER_SELL: '494px',
    HOME_MARQUEE_1: '230px',
    HOME_MARQUEE_2: '460px',
    INCENTIVES_CORE: '174px',
    SPONSORED_CARS: '501px',
    MARKETING_SURVEY: '166px',
    MARKETING_SURVEY_2ROW: '166px',
    PRICE_CHECKER: '595px',
    TRANSPARENT_PRICING_LEAD_FORM: '810px',
    LOW_INVENTORY_LEAD_FORM: '1100px',
    UNIFIED_LEAD_FORM: '1050px',
    ARTICLE_BY_LINE: '150px',
    DEALER_INVENTORY_CAROUSEL: '436px',
    NEWS_WIDGET: '920px',
    TRACK_CAR_VALUE_MODULE: '473px',
    INVENTORIES_FOR_SALE: '1155px',
    ESTIMATED_VALUES: '356.5px',
    ESTIMATED_VALUE_VEHICLE_DROPDOWNS: '300.5px',
    PRICE_CHECKER_LEAD_FORM: '780px',
    DEPRECATION_GRAPHIC_VEHICLE_DROPDOWNS: '314px',
    HVS_MODULE: '628px',
  },
  DESKTOP: {
    SUGGESTED_PRICING_CAROUSEL: '527px',
    SUGGESTED_VEHICLE_PRICING_CAROUSEL: '527px',
    CORE_FOOTER_PRICING_CAROUSEL: '489px',
    APPRAISER_SELL: '258px',
    HOME_MARQUEE_1: '227px',
    HOME_MARQUEE_2: '454px',
    INCENTIVES_CORE: '174px',
    SPONSORED_CARS: '515px',
    MARKETING_SURVEY: '106px',
    MARKETING_SURVEY_2ROW: '144px',
    PRICE_CHECKER: '355px',
    TRANSPARENT_PRICING_LEAD_FORM: '490px',
    LOW_INVENTORY_LEAD_FORM: '620px',
    UNIFIED_LEAD_FORM: '655px',
    ARTICLE_BY_LINE: '80px',
    DEALER_INVENTORY_CAROUSEL: '500px',
    NEWS_WIDGET: '460px',
    TRACK_CAR_VALUE_MODULE: '262px',
    HIGH_IMPACT: '572px',
    INVENTORIES_FOR_SALE: '477px',
    ESTIMATED_VALUES: '312.5px',
    ESTIMATED_VALUE_VEHICLE_DROPDOWNS: '201.5px',
    PRICE_CHECKER_LEAD_FORM: '445px',
    DEPRECATION_GRAPHIC_VEHICLE_DROPDOWNS: '210px',
    HVS_MODULE: '572px',
  },
};

/**
 * This is a static ad wrapper to force a min height for reducing cls
 */
export function MinHeightWrapperUI({ children, componentType, className, isMobile, forceStaticHeight }) {
  const componentHeights = isMobile ? COMPONENT_HEIGHTS.MOBILE : COMPONENT_HEIGHTS.DESKTOP;
  const componentHeightValue = componentType ? componentHeights[componentType] : 'auto';

  const containerStyles = {
    ...(forceStaticHeight ? { height: componentHeightValue } : { minHeight: componentHeightValue }),
    overflow: forceStaticHeight ? 'hidden' : 'visible',
  };

  return (
    <FeatureFlag name="disableMinHeightWrapper">
      {enabled =>
        enabled ? (
          <div className={className}>{children}</div>
        ) : (
          <div className={className} style={containerStyles}>
            {children}
          </div>
        )
      }
    </FeatureFlag>
  );
}

MinHeightWrapperUI.propTypes = {
  forceStaticHeight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  componentType: PropTypes.string,
  isMobile: PropTypes.bool,
};

MinHeightWrapperUI.defaultProps = {
  forceStaticHeight: false,
  className: null,
  componentType: null,
  isMobile: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const MinHeightWrapper = connect(mapStateToProps)(MinHeightWrapperUI);
